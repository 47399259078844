<template>
  <div :class="{ 'ly-is-full': isFull }">
    <!-- 表格头部操作栏 -->
    <div class="tableToolBar" ref="tableToolBar">
      <el-button @click="handleEdit('', 'add')" type="primary" icon="Plus">新增</el-button>
    </div>
    <div class="table">
      <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="tankList" ref="tableref" v-loading="loadingPage"
        style="width: 100%">
        <el-table-column width="80" prop="id" label="id"></el-table-column>
        <el-table-column width="160" prop="task_type_name" label="任务类型名称" show-overflow-tooltip></el-table-column>
        <el-table-column width="160" prop="task_name" label="任务名称" show-overflow-tooltip></el-table-column>
        <el-table-column width="100" prop="product_list[0].product_id" label="产品id"></el-table-column>
        <el-table-column width="100" prop="product_list[0].title" label="产品名称"></el-table-column>
        <el-table-column width="100" label="产品封面图">
          <template #default="scope">
            <el-image :src="scope.row.product_list[0].cover" :preview-src-list="[scope.row.product_list[0].cover]"
              :preview-teleported="true" style="width: 30px;height: 30px"></el-image>
          </template>
        </el-table-column>
        <el-table-column width="100" prop="product_list[0].price" label="售价">
          <template #default="scope">
            {{ scope.row.product_list[0].price / 100 || '' }}
          </template>
        </el-table-column>
        <el-table-column width="160" prop="start_date" label="任务开始日期" show-overflow-tooltip></el-table-column>
        <el-table-column width="160" prop="end_date" label="任务结束日期" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column width="160" prop="reward_amount" label="奖励金额" show-overflow-tooltip></el-table-column> -->
        <el-table-column width="160" prop="reward_explain" label="奖励说明" show-overflow-tooltip></el-table-column>
        <el-table-column width="160" prop="tips" label="温馨提示" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column width="160" prop="condition_num" label="出视频/出单数量" show-overflow-tooltip></el-table-column> -->
        <el-table-column width="100" label="任务类型">
          <template #default="scope">
            {{ scope.row.task_type === 1 ? '投流任务' : '现金任务' }}
          </template>
        </el-table-column>
        <el-table-column width="100" label="奖励条件">
          <template #default="scope">
            {{ scope.row.condition_type === 1 ? '出视频就投' : scope.row.condition_type === 2 ? '出单就投' : '活动专属' }}
          </template>
        </el-table-column>
        <el-table-column width="170" prop="create_datetime" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column width="170" prop="update_datetime" label="更新时间" show-overflow-tooltip></el-table-column>
        <!-- 操作列 -->
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <!-- <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon><full-screen /></el-icon>
                </el-tooltip>
              </div> -->
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')">编辑</span>
            <span class="table-operate-btn" v-if="!scope.row.is_delete"
              @click="handleEdit(scope.row, 'delete')">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
  </div>
  <LyDialog v-model="isDialogShow" v-if="isDialogShow" :title="dialogTitle" width="700px" top="30px"
    :before-close="handleDialogClose">
    <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm"
      :disabled="!isShowBtn('personalCenter', '个人中心', 'Update')" required-asterisk center label-width="120px">
      <el-form-item label="任务名称:" prop="task_name">
        <el-input v-model="formData.task_name" placeholder="请输入任务名称" style="width: 100%;"></el-input>
      </el-form-item>
      <el-form-item label="任务类型:" prop="task_type">
        <el-radio-group v-model="formData.task_type">
          <el-radio :label="1">投流任务</el-radio>
          <el-radio :label="2">现金任务</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="任务日期:">
        <el-date-picker style="width: 100%" v-model="start_date" type="daterange" start-placeholder="任务开始日期"
          format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="To" end-placeholder="任务结束日期" />
      </el-form-item>
      <el-form-item label="奖励说明:" prop="reward_explain">
        <el-input size="default" v-model="formData.reward_explain" maxlength="255" clearable placeholder="请输入奖励说明"
          type="textarea" @change="search" style="width:100%;height: 300rpx;"></el-input>
      </el-form-item>
      <el-form-item label="温馨提示:" prop="tips">
        <el-input size="default" v-model="formData.tips" maxlength="255" clearable placeholder="请输入温馨提示" type="textarea"
          @change="search" style="width:100%;height: 300rpx;"></el-input>
      </el-form-item>
      <el-form-item label="奖励条件类型:" prop="condition_type">
        <el-radio-group v-model="formData.condition_type">
          <el-radio :label="1">出视频</el-radio>
          <el-radio :label="2">出单</el-radio>
          <el-radio :label="3">活动专属</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-switch v-model="formData.status" active-color="#13ce66" inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
      <el-form-item label="任务商品类目：" prop="category_id">
        <el-select v-model="category_id" placeholder="请选择" clearable style="width: 320px;">
          <el-option v-for="item in tabtankList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="奖励列表：" prop="reward_list">
        <!-- "reward_amount": 100, //金额
          "conditions": [
          "带货等级1",
          "粉丝数达到1000"
          ] //条件列表 -->
        <!-- <el-switch v-model="status" active-color="#13ce66" inactive-color="#ff4949">
        </el-switch> -->
        <div v-for="item, k in formData.reward_list" :key="k">
          <div>
            <div></div>
            <el-input size="default" v-model="item.reward_amount" maxlength="255" clearable
              placeholder="请输入金额"></el-input>
          </div>
          <div>
            <div></div>
            <el-input size="default" v-model="item.conditions" maxlength="255" clearable placeholder="请输入条件"
              type="textarea" style="width:100%;height: 300rpx;"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="轮播图:" prop="name">
        <LyUploadAvatar v-model="banner_list" width="80px" height="80px"></LyUploadAvatar>
      </el-form-item>
      <div class="ly-title">
        添加商品
      </div>
      <el-form-item prop="goods_info">
        <el-table :data="formData.goods_info" ref="lyaddtable" border stripe>
          <el-table-column type="index" width="60" fixed="left">
            <template #header>
              <LyTableSelect button-type="primary" placement="right" :tableIndex="false" button-icon="plus" size="small"
                :button-circle="true" ref="lymanualSelect" :apiObj="getGoodsApi" @click="handleManualSelectClick"
                :table-width="1000" :multiple="false" @confirm="handleManualConfirmClick">
                <template #header="{ form, submit }">
                  <el-form :inline="true" :model="form">
                    <el-form-item>
                      <el-input type="text" v-model="form.title" placeholder="商品名称" style="width: 160px;"
                        clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input type="text" v-model="form.product_id" placeholder="商品ID" style="width: 160px;"
                        clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-select v-model="form.status" placeholder="状态" clearable style="width: 120px;">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-select v-model="form.source" placeholder="来源" clearable style="width: 120px;">
                        <el-option v-for="item in sourceList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item>
                                                <el-date-picker
                                                    size="small"
                                                    style="width:320px"
                                                    v-model="form.timerange"
                                                    type="datetimerange"
                                                    range-separator="至"
                                                    start-placeholder="开始时间"
                                                    end-placeholder="结束时间">
                                                </el-date-picker>
                                            </el-form-item> -->
                    <el-form-item>
                      <el-button type="primary" @click="submit">查询</el-button>
                    </el-form-item>
                  </el-form>
                </template>
                <el-table-column prop="image" label="图片" width="80">
                  <template #default="scope">
                    <el-image :src=scope.row.cover :preview-src-list="[scope.row.cover]" :preview-teleported="true"
                      style="width: 30px;height: 30px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="title" label="名称" show-overflow-tooltip min-width="200"></el-table-column>
                <el-table-column width="90" prop="price" label="价格">
                  <template #default="scope">
                    {{ scope.row.price / 100 }}
                  </template>
                </el-table-column>
                <el-table-column width="110" prop="source_name" label="来源"></el-table-column>
                <el-table-column min-width="180" prop="product_id" label="商品ID" show-overflow-tooltip></el-table-column>

              </LyTableSelect>
            </template>
            <template #default="scope">
              <div class="ly-form-table-handle">
                <span>{{ scope.$index + 1 }}</span>
                <el-button type="danger" icon="delete" size="small" plain circle
                  @click="rowDel(scope.row, scope.$index)"></el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="商品名称" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cover" label="商品图" width="80">
            <template #default="scope">
              <el-image :src=scope.row.cover :preview-src-list="[scope.row.cover]" :preview-teleported="true"
                style="width: 40px;height: 40px"></el-image>
            </template>
          </el-table-column>
          <el-table-column width="90" prop="price" label="价格">
            <template #default="scope">
              {{ scope.row.price / 100 }}
            </template>
          </el-table-column>
          <el-table-column width="110" prop="source_name" label="来源"></el-table-column>
          <el-table-column min-width="180" prop="product_id" label="商品ID" show-overflow-tooltip></el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
      <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
    </template>
  </LyDialog>
</template>
<script>
import { douyintaskManage, douyinProductmanage, douyintaskManageAdd, douyintaskManageEdit, douyintaskManageDel, douyintaskProductCategory } from '@/api/api'
import { ElMessage } from 'element-plus'
import Pagination from "@/components/Pagination"
import LyDialog from "@/components/dialog/dialog2.vue"
import LyTableSelect from "@/components/lyTableSelect";
import LyUploadAvatar from "@/components/upload/goods";
export default {
  components: {
    Pagination, LyDialog, LyTableSelect, LyUploadAvatar
  },
  name: 'tankManage',
  data () {
    return {
      statusList: [
        { id: 1, name: '正常' },
        { id: 0, name: '禁用' }
      ],
      sourceList: [
        { id: 0, name: '精选联盟' },
        { id: 1, name: '团长活动' },
      ],
      isFull: false,
      tableHeight: 600,
      loadingPage: false,
      tankList: [],
      pageparm: {
        page: 1,
        limit: 10,
        total: 0,
      },
      isDialogShow: false,
      dialogTitle: '添加任务',
      formData: {
        task_name: '',//任务名称
        task_type: '',//任务类型 1投流任务  2现金任务
        start_date: '',//任务开始日期
        end_date: '', //任务结束日期
        banner_list: [], //轮播图
        reward_list: [//奖励列表
          { reward_amount: '', conditions: [] }
        ],
        reward_explain: '',//奖励说明
        tips: '',//温馨提
        condition_type: '', //奖励条件类型  1出视频  2出单 3活动专属
        status: 1, //状态 0禁用 1启用
        goods_info: [],
        product_list: []//关联商品列表
      },
      start_date: [],
      loadingSave: false,
      rules: [],
      banner_list: [],
      category_id: '',
      tabtankList: []
    }
  },
  methods: {
    // 列表数据
    getdouyintaskManage () {
      douyintaskManage(this.pageparm).then(res => {
        if (res.code == 2000) {
          this.tankList = res.data.data
          this.pageparm.page = res.data.page
          this.pageparm.limit = res.data.limit
          this.pageparm.total = res.data.total
        } else {
          ElMessage({ message: res.msg, type: 'warning' })
        }
      })
    },
    // 点击分页
    callFather (parm) {
      this.pageparm.page = parm.page
      this.pageparm.limit = parm.limit
      this.getdouyintaskManage()
    },

    handleEdit (row, flag) {
      if (flag == 'add') {
        this.formData = {
          task_name: '',//任务名称
          task_type: '',//任务类型 1投流任务  2现金任务
          reward_list: [//奖励列表
            { reward_amount: '', conditions: [] }
          ],
          reward_explain: '',//奖励说明
          tips: '',//温馨提
          condition_type: '', //奖励条件类型  1出视频  2出单 3活动专属
          status: '', //状态 0禁用 1启用
          goods_info: [],
        }
        this.start_date = []
        this.banner_list = []
        this.isDialogShow = true
      } else if (flag == 'edit') {
        this.formData = {
          task_name: row.task_name,//任务名称
          task_type: row.task_type,//任务类型 1投流任务  2现金任务
          reward_list: row.reward_list || [//奖励列表
            { reward_amount: '', conditions: [] }
          ],
          reward_explain: row.reward_explain,//奖励说明
          tips: row.tips,//温馨提
          condition_type: row.condition_type, //奖励条件类型  1出视频  2出单 3活动专属
          status: 1, //状态 0禁用 1启用
          goods_info: row.product_list,
        }
        this.start_date = [row.start_date, row.end_date]
        let arr = []
        row.banner_list.map(item => {
          arr.push({ pic: item })
        })
        this.category_id = row.product_list[0].category_id
        this.banner_list = arr
        this.formData.id = row.id
        this.dialogTitle = '编辑任务'
        this.isDialogShow = true
      } else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', {
          closeOnClickModal: false
        }).then(res => {
          douyintaskManageDel({ id: row.id }).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getdouyintaskManage()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }
    },
    getGoodsApi () {
      return douyinProductmanage
    },
    handleManualSelectClick () {
      this.$refs.lymanualSelect.handleChooseClick()
    },
    handleManualConfirmClick (items) {
      console.log(items);
      //去重赋值
      this.formData.goods_info[0] = items
    },
    /** 添加表格操作 */
    rowDel (row, index) {
      this.formData.goods_info.splice(index, 1)
    },
    search () {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    handleDialogClose () {
      this.isDialogShow = false
      this.formData = {
        group_info: [],
        fjsucai: [],
        video_script: []
      }
      this.inputVisibleTagId = 0
      this.inputValueTag = ""
      this.inputVisibleTag = false
      this.fjsucai_select = []
      this.tabsActiveName = 'fjsc'
    },
    submitData () {
      if (!this.category_id) return this.$message.warning('请选择任务商品类目')
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          param.status = this.formData.status === 1 ? 0 : 1
          param.start_date = this.start_date[0]
          param.end_date = this.start_date[1]
          let arr = []
          this.banner_list.map(item => {
            arr.push(item.pic)
          })
          param.banner_list = arr
          // if (!param.video_script) {
          //   param.video_script = []
          // }
          param.goods_info.map(item => {
            item.category_id = this.category_id
          })
          param.product_list = param.goods_info
          if (this.formData.id) {
            douyintaskManageEdit(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getdouyintaskManage()
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            douyintaskManageAdd(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getdouyintaskManage()
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    }
  },
  created () {
    this.getdouyintaskManage()
    douyintaskProductCategory({
      page: 1,
      limit: 1000,
      total: 0,
    }).then(res => {
      if (res.code == 2000) {
        this.tabtankList = res.data.data
      } else {
        ElMessage({ message: res.msg, type: 'warning' })
      }
    })
  },
  mounted () { },
  unmounted () { },
}
</script>
<style>
.el-form-item__content {
  margin-left: 0 !important;
}
</style>