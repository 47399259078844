<!--
 * @Descripttion: 单视频上传(支持截取视频第一帧作为封面和视频时长和文件大小)
 * @version: 1.1
 * @Program: django-vue-lyadmin
 * @Author: lybbn
 * @QQ: 1042594286
 * @Date: 2023/08/16
-->
<template>
  <el-upload class="video-uploader" action="" :accept="accept" :limit="limit" :disabled="disabled" :multiple="true"
    :show-file-list="true" ref="uploadDefaultImage" :before-upload="before" :on-remove="handleVODRemove"
    :http-request="imgUploadRequest" :on-preview="handlePreview" :on-success="imgUploadSuccess" :auto-upload="true">
    <template #trigger>
      <el-button type="primary" :size="size">{{ buttonText }}</el-button>
    </template>
  </el-upload>
  <video src="" id="myvideo" style="position: fixed">您的浏览器不支持 video 标签。</video>
</template>

<script>
import { douyinLspGoodsFJUpload, platformsettingsUploadPlatformImg } from "@/api/api";
export default {
  name: "LyUploadVideo",
  data () {
    return {
      //视频上传
      filesList: [],
    }
  },
  props: {
    limit: {
      type: Number,
      default: 10
    },
    accept: {
      type: String,
      default: ".mp4,.MP4,.flv,.FLV,.mov"
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '140px'
    },
    size: {
      type: String,
      default: 'default'
    },
    buttonText: {
      type: String,
      default: '点击上传'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    //文件大小单位M
    maxSize: {
      type: Number,
      default: 500
    },
    group: {
      type: Number,
      default: 0
    },
    coverQuality: {
      type: Number,
      default: 0.5
    }
  },
  methods: {
    handlePreview (uploadFile) {
      window.open(uploadFile.url)
    },
    //文件删除
    handleVODRemove (file, fileList) {

    },
    base64toFile (baseUrl, filename = 'file') {
      let arr = baseUrl.split(',');
      let type = arr[0].match(/:(.*?);/)[1];   // 解锁图片类型
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length
      let bufferArray = new Uint8Array(n);
      while (n--) {
        bufferArray[n] = bytes.charCodeAt(n);
      }
      return new File([bufferArray], filename, { type });
    },
    getFileName (file) {
      const type = file.type.split("/")[1];
      let name = '';
      if (type) {
        name = file.name.substring(0, file.name.indexOf(`.${type}`));
      }
      return `${name}.png`;
    },
    //截取视频第一帧作为播放前默认图片
    async findvideocover (file) {
      const video = document.getElementById("myvideo"); // 获取视频对象
      video.muted = true;
      video.autoplay = true;
      video.preload = true;
      video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1 // 第一帧
      video.src = URL.createObjectURL(file)
      // const video = document.createElement("video") // 也可以自己创建video
      // video.setAttribute('crossOrigin', 'anonymous'); // 处理跨域
      // video.setAttribute('src', URL.createObjectURL(file));
      // video.setAttribute('currentTime', 1);
      // video.setAttribute('preload', "auto");
      video.oncanplay = () => {
        var canvas = document.createElement('canvas') // 获取 canvas 对象
        const ctx = canvas.getContext('2d'); // 绘制2d
        canvas.width = this.coverQuality * video.clientWidth; // 获取视频宽度
        canvas.height = this.coverQuality * video.clientHeight; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, this.coverQuality * video.clientWidth, this.coverQuality * video.clientHeight)
        // 转换成base64形式
        var imgsrc = canvas.toDataURL("image/png") // 截取后的视频封面
        file.url = imgsrc
        // base64转成bolb文件
        const fileBolb = this.base64toFile(imgsrc, this.getFileName(file))
        // 把首图上传生成云上地址
        platformsettingsUploadPlatformImg({ file: fileBolb }).then(res => {
          file.cover = res.data.data[0]
          video.src = ""
          canvas.remove();
        });
      }
    },
    // 视频上传文件验证
    async before (file) {
      // this.filesList = []
      // console.log(file)
      if (file) {
        const isLt1G = file.size / 1024 / 1024 < this.maxSize;
        if (!isLt1G) {
          this.$message.error(`上传文件大小不能超过 ${this.maxSize}MB!`);
          return false;
        }
        var fileName = file.name || "";
        var ext = fileName.split(".")[fileName.split(".").length - 1];
        if (ext == "mp4") {
          // 获取视频时长
          var url = URL.createObjectURL(file);
          var audioElement = new Audio(url);
          var duration = 0;
          audioElement.addEventListener("loadedmetadata", function (_event) {
            duration = audioElement.duration; //时长为秒，小数，182.36
            file.duration = duration
          });
          // await this.findvideocover(file)
        }
      }
    },
    async imgUploadRequest (param) {
      var vm = this
      let newparam = param
      newparam['customParams'] = { group: this.group, fileSize: newparam.file.size, duration: newparam.file.duration }
      let obj = await douyinLspGoodsFJUpload(newparam)
      if (obj.code == 2000) {
        param.onSuccess(obj)
      } else {
        param.onError(obj.msg || "未知错误")
      }
    },
    async imgUploadSuccess (res, file, fileList) {
      let tempfilelist = []
      if (res) {
        let data = res.data
        // data.cover = file.raw.cover
        data.seconds = parseInt(file.raw.duration)
        // if(data.cover == "" || !(!!data.cover)){
        //     await this.findvideocover(file.raw)
        //     data.cover = file.raw.cover
        // }
        tempfilelist.push(data)
        this.filesList.push(data)
        this.$emit('onSuccess', tempfilelist);
      }
      // this.$refs.uploadDefaultImage.clearFiles()
      if (fileList.length == this.filesList.length) {
        this.$refs.uploadDefaultImage.clearFiles()
        this.filesList = []
      }
    }
  },
}
</script>

<style scoped>
.video-uploader {
  width: 100%;
}

.ly-video-uploader-icon {
  font-size: 28px !important;
  color: #8c939d;
  text-align: center
}
</style>