<script setup>
import { onMounted, reactive, ref } from 'vue'
import { douyinvipInfo, platformsettingsUploadPlatformImg, douyinvipInfoAdd, douyinvipInfoCont, douyinvipInfoEdit, douyinvipInfoDel } from '@/api/api'
import { ElMessage } from 'element-plus'
import Pagination from "@/components/Pagination"
import LyDialog from "@/components/dialog/dialog2.vue"

const isFull = ref(false)
const tableHeight = ref(500)
const loadingPage = ref(false)
const vipList = ref([])
const pageparm = reactive({
  page: 1,
  limit: 10,
  total: 0
})

// 列表数据
const getdouyinvipInfo = async () => {
  douyinvipInfo({ page: pageparm.page, limit: pageparm.limit }).then(res => {
    if (res.code == 2000) {
      vipList.value = res.data.data
      pageparm.page = res.data.page
      pageparm.limit = res.data.limit
      pageparm.total = res.data.total
    }
  })
}
onMounted(() => {
  getdouyinvipInfo()
})
// 点击分页
const callFather = (parm) => {
  pageparm.page = parm.page
  pageparm.limit = parm.limit
  getdouyinvipInfo()
}

const isDialogShow = ref(false)
const dialogTitle = ref('添加套餐')
const formData = reactive({
  name: '', //套餐名称
  cover: '', //图片
  original_price: '', //原价 单位：分
  now_price: '', //现价 单位：分
  validity_days: '', //有效天数
  status: '',
  sort: '' //排序
})
const loadingSave = ref(false)
const handleEdit = (row, flag) => {
  if (flag == 'add') {
    formData.name = ''
    formData.cover = ''
    formData.original_price = ''
    formData.now_price = ''
    formData.validity_days = ''
    formData.sort = ''
    formData.id = ''
    isDialogShow.value = true
  } else if (flag == 'edit') {
    douyinvipInfoCont({ id: row.id }).then(res => {
      if (res.code == 2000) {
        formData.name = res.data.data.name
        formData.cover = res.data.data.cover
        formData.original_price = res.data.data.original_price
        formData.now_price = res.data.data.now_price
        formData.validity_days = res.data.data.validity_days
        formData.sort = res.data.data.sort
        // formData.status = res.data.data.status
        formData.id = res.data.data.id
        dialogTitle.value = '编辑套餐'
        isDialogShow.value = true
      } else {
        ElMessage({ message: res.msg, type: 'warning' })
      }
    })
  } else if (flag == 'editsort') {
    douyinvipInfoDel({ id: row.id }).then(res => {
      if (res.code == 2000) {
        ElMessage({ message: res.msg, type: 'success' })
        getdouyinvipInfo()
      } else {
        ElMessage({ message: res.msg, type: 'warning' })
      }
    })
  }
}
const imgUploadRequest = async (param) => {
  let res = await platformsettingsUploadPlatformImg(param)
  if (res.code == 2000) {
    formData.cover = res.data.data[0]
  } else {
    ElMessage({ message: res.msg, type: 'warning' })
  }
}
const imgBeforeUpload = (file) => {
  const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJPG) {
    ElMessage({ message: '图片只能是 JPG/PNG 格式!', type: 'error' })
    return false
  }
  return isJPG
}
const rulesForm = ref(null)
const rules = ref({
  name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' },],
  cover: [{ required: true, message: '请上传图片', trigger: 'blur' },],
  original_price: [{ required: true, message: '请输入原价', trigger: 'blur' },],
  now_price: [{ required: true, message: '请输入现价', trigger: 'blur' },],
  validity_days: [{ required: true, message: '请输入有效天数', trigger: 'blur' },],
  sort: [{ required: true, message: '请输入排序', trigger: 'blur' },],
  status: [{ required: true, message: '请选择状态', trigger: 'blur' },],
})
const submitData = () => {
  if (formData.now_price > formData.original_price) return ElMessage({ message: '现价不能比原价高', type: 'warning' })
  if (!rulesForm.value) return
  rulesForm.value.validate(valid => {
    if (valid) {
      loadingSave.value = true
      let param = {
        ...formData
      }
      if (dialogTitle.value == '编辑套餐') {
        douyinvipInfoEdit(param).then(res => {
          loadingSave.value = false
          console.log(res);
          if (res.code == 2000) {
            isDialogShow.value = false
            ElMessage({ message: res.msg, type: 'success' })
            getdouyinvipInfo()
          } else {
            ElMessage({ message: res.msg, type: 'warning' })
          }
        })
      } else {
        douyinvipInfoAdd(param).then(res => {
          loadingSave.value = false
          console.log(res);
          if (res.code == 2000) {
            isDialogShow.value = false
            ElMessage({ message: res.msg, type: 'success' })
            getdouyinvipInfo()
          } else {
            ElMessage({ message: res.msg, type: 'warning' })
          }
        })
      }
    }
  })
}
const handleDialogClose = () => {
  isDialogShow.value = false
}
</script>
<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="table">
      <!-- 表格头部操作栏 -->
      <div class="tableToolBar" ref="tableToolBar">
        <el-button @click="handleEdit('', 'add')" type="primary" icon="Plus">新增</el-button>
      </div>
      <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="vipList" ref="tableref" v-loading="loadingPage"
        style="width: 100%">
        <el-table-column width="80" prop="id" label="套餐id"></el-table-column>
        <el-table-column width="200" prop="name" label="套餐名称" show-overflow-tooltip></el-table-column>
        <el-table-column width="90" prop="cover" label="图片">
          <template #default="scope">
            <el-image :src="scope.row.cover" :preview-src-list="[scope.row.cover]" :preview-teleported="true"
              style="width: 30px;height: 30px" :onerror="defaultImg"></el-image>
          </template>
        </el-table-column>
        <el-table-column width="80" prop="original_price" label="原价">
          <template #default="scope">
            {{ scope.row.original_price / 100 }}
          </template>
        </el-table-column>
        <el-table-column width="80" prop="now_price" label="现价">
          <template #default="scope">
            {{ scope.row.now_price / 100 }}
          </template>
        </el-table-column>
        <el-table-column width="90" prop="validity_days" label="有效天数"></el-table-column>
        <el-table-column width="170" prop="create_datetime" label="创建时间"></el-table-column>
        <el-table-column width="170" prop="update_datetime" label="更新时间"></el-table-column>
        <el-table-column width="70" prop="sort" label="排序"></el-table-column>
        <el-table-column width="100" prop="status" label="是否启用">
          <template #default="scope">
            {{ scope.row.status ? '启用' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column width="100" prop="status" label="是否删除">
          <template #default="scope">
            {{ scope.row.is_delete ? '已删除' : '未删除' }}
          </template>
        </el-table-column>
        <!-- 操作列 -->
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')">编辑套餐</span>
            <span class="table-operate-btn" v-if="!scope.row.is_delete"
              @click="handleEdit(scope.row, 'editsort')">删除套餐</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
  </div>
  <LyDialog v-model="isDialogShow" :title="dialogTitle" width="60%" top="30px" :before-close="handleDialogClose">
    <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="top" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="套餐名称" prop="name">
            <el-input v-model="formData.name" placeholder="请输入套餐名称" style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图片：" prop="cover" style="padding: 10px">
            <el-upload ref="lyimagupload" class="avatar-uploader" :limit="1" action="" :show-file-list="false"
              :http-request="imgUploadRequest" :before-upload="imgBeforeUpload">
              <img v-if="formData.cover" :src="formData.cover" class="avatar">
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="原价(单位:分)" prop="original_price">
            <el-input v-model="formData.original_price" placeholder="请输入原价(单位:分)" style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="现价(单位:分)" prop="now_price">
            <el-input v-model="formData.now_price" placeholder="请输入现价(单位:分)" style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="有效天数" prop="validity_days">
            <el-input-number v-model="formData.validity_days" placeholder="请输入有效天数"
              style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="formData.sort" placeholder="请输入排序" style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态" prop="status">
            <el-select v-model="formData.status" placeholder="请选择">
              <el-option label="启用" value=1></el-option>
              <el-option label="禁用" value=0>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
      <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
    </template>
  </LyDialog>
</template>
<style>
.tableToolBar {
  margin-left: 1px;
  margin-right: 1px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  background: var(--el-bg-color);
  padding: 8px;
  /*box-shadow: 0 0 4px #cccccc;*/
  box-shadow: 0 0 2px rgba(0, 0, 0, .12);
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>