<script setup>
import { onMounted, reactive, ref } from 'vue'
import { douyintaskProductCategory, douyintaskProductCategoryAdd, douyintaskProductCategoryEdit, douyintaskProductCategoryDel } from '@/api/api'
import { ElMessage } from 'element-plus'
import Pagination from "@/components/Pagination"
import LyDialog from "@/components/dialog/dialog2.vue"

const isFull = ref(false)
const tableHeight = ref(500)
const loadingPage = ref(false)
const tankList = ref([])
const pageparm = reactive({
  page: 1,
  limit: 10,
  total: 0,
})

// 列表数据
const getdouyintaskProductCategory = async () => {
  douyintaskProductCategory(pageparm).then(res => {
    if (res.code == 2000) {
      tankList.value = res.data.data
      pageparm.page = res.data.page
      pageparm.limit = res.data.limit
      pageparm.total = res.data.total
    } else {
      ElMessage({ message: res.msg, type: 'warning' })
    }
  })
}
onMounted(() => {
  getdouyintaskProductCategory()
})
// 点击分页
const callFather = (parm) => {
  pageparm.page = parm.page
  pageparm.limit = parm.limit
  getdouyintaskProductCategory()
}

const isDialogShow = ref(false)
const dialogTitle = ref('添加类目')
const formData = ref({
  name: '',
  sort: ''
})
const rulesForm = ref(null)
const rules = ref({
  name: [{ required: true, message: '请输入名称', trigger: 'blur' },],
  sort: [{ required: true, message: '请输入排序', trigger: 'blur' },],
})
const handleEdit = (row, flag) => {
  if (flag == 'add') {
    formData.value.name = ''
    formData.value.sort = ''
    formData.value.id = ''
    isDialogShow.value = true
  } else if (flag == 'edit') {
    formData.value.name = row.name
    formData.value.sort = row.sort
    formData.value.id = row.id
    dialogTitle.value = '编辑类目'
    isDialogShow.value = true
  } else if (flag == 'editsort') {
    douyintaskProductCategoryDel({ id: row.id }).then(res => {
      if (res.code == 2000) {
        ElMessage({ message: res.msg, type: 'success' })
        pageparm.page = 1
        getdouyintaskProductCategory()
      } else {
        ElMessage({ message: res.msg, type: 'warning' })
      }
    })
  }
}
const loadingSave = ref(false)
const submitData = () => {
  if (!rulesForm.value) return
  rulesForm.value.validate(async valid => {
    if (valid) {
      loadingSave.value = true
      let param = {
        ...formData.value
      }
      if (dialogTitle.value == '编辑类目') {
        douyintaskProductCategoryEdit(param).then(res => {
          loadingSave.value = false
          if (res.code === 2000) {
            isDialogShow.value = false
            ElMessage({ message: res.msg, type: 'success' })
            pageparm.page = 1
            getdouyintaskProductCategory()
          } else {
            ElMessage({ message: res.msg, type: 'warning' })
          }
        })
      } else {
        douyintaskProductCategoryAdd(param).then(res => {
          loadingSave.value = false
          if (res.code === 2000) {
            isDialogShow.value = false
            ElMessage({ message: res.msg, type: 'success' })
            pageparm.page = 1
            getdouyintaskProductCategory()
          } else {
            ElMessage({ message: res.msg, type: 'warning' })
          }
        })
      }
    }
  })
}

const handleDialogClose = () => {
  isDialogShow.value = false
  formData.value = {
    name: '',
    sort: ''
  }
} 
</script>
<template>
  <div :class="{ 'ly-is-full': isFull }">
    <!-- 表格头部操作栏 -->
    <div class="tableToolBar" ref="tableToolBar">
      <el-button @click="handleEdit('', 'add')" type="primary" icon="Plus">新增</el-button>
    </div>
    <div class="table">
      <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="tankList" ref="tableref" v-loading="loadingPage"
        style="width: 100%">
        <el-table-column width="80" prop="id" label="id"></el-table-column>
        <el-table-column width="120" prop="name" label="名称" show-overflow-tooltip></el-table-column>
        <el-table-column width="160" prop="sort" label="排序" show-overflow-tooltip></el-table-column>
        <el-table-column width="100" label="状态">
          <template #default="scope">
            {{ scope.row.status ? '启用' : '禁用' }}
          </template>
        </el-table-column>
        <!-- 操作列 -->
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')">编辑</span>
            <span class="table-operate-btn" v-if="!scope.row.is_delete"
              @click="handleEdit(scope.row, 'editsort')">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
  </div>
  <LyDialog v-model="isDialogShow" v-if="isDialogShow" :title="dialogTitle" width="60%" top="30px"
    :before-close="handleDialogClose">
    <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="top" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="名称" prop="name">
            <el-input v-model="formData.name" placeholder="请输入名称" style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="formData.sort" placeholder="请输入套餐名称" style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
      <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
    </template>
  </LyDialog>
</template>
<style></style>