<!--
 * @Descripttion: 单视频上传
 * @version: 1.0
 * @Program: django-vue-lyadmin
 * @Author: lybbn
 * @QQ: 1042594286
 * @Date: 2023/07/24
-->
<template>
  <el-upload class="video-uploader" action="" :accept="accept" :limit="limit" :disabled="disabled"
    :show-file-list="false" ref="uploadDefaultImage" :on-change="handleChageVideo" :on-remove="handleVODRemove"
    :http-request="imgUploadRequest" :on-preview="handlePreview" :on-progress="handleProgress"
    :on-success="imgUploadSuccess" :auto-upload="true">
    <template #trigger>
      <el-button type="primary">点击上传</el-button>
    </template>
    <div style="width: 100%">
      <el-progress :percentage="percentage" v-if="percentage < 100 && percentage > 0 && showProgress"
        style="width: 100%" />
      <el-progress :percentage="percentage" status="success" v-if="percentage == 100 && showProgress"
        style="width: 100%" />
    </div>
    <el-input v-model="videourl" placeholder="请上传或输入视频地址（播放链接）" :disabled="disabled" clearable
      style="width: 100%"></el-input>
    <div v-if="videourl" style="margin-top:5px ;">
      <video :style="[{ width: width }, { height: height }, { objectFit: 'fill' }]" :autoplay="false" :src="videourl"
        controls></video>
    </div>
  </el-upload>
</template>

<script>
import { platformsettingsUploadPlatformImg } from "@/api/api";
export default {
  name: "LyUploadAvatar",
  data () {
    return {
      videourl: "",
      //视频上传
      files: "",
      showProgress: false,
      percentage: 0,
    }
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    limit: {
      type: Number,
      default: 1
    },
    accept: {
      type: String,
      default: ".mp4,.MP4,.flv,.FLV,.mov"
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '140px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    //文件大小单位M
    maxSize: {
      type: Number,
      default: 500
    }
  },
  created () {
    this.videourl = this.modelValue
  },
  watch: {
    modelValue: function (nval) {
      this.videourl = nval; // modelValue改变是同步子组件visible的值
    },
    videourl: function (nval) {
      this.$emit('update:modelValue', nval); // visible改变时同步父组件modelValue的值
    }
  },
  methods: {
    handlePreview (uploadFile) {
      window.open(uploadFile.url)
    },
    //文件删除
    handleVODRemove (file, fileList) {
      // 如果正在分片上传，则取消分片上传
      if (file.percentage !== 100) {
        this.$store.getters.chunkUploadXhr.forEach(item => {
          item.abort()//取消上传
        })
      }
    },
    // 视频上传文件验证
    handleChageVideo (file, fileList) {
      if (file) {
        const isLt1G = file.size / 1024 / 1024 < this.maxSize;
        if (!isLt1G) {
          this.$message.error(`上传文件大小不能超过 ${this.maxSize}MB!`);
          return false;
        }
        this.files = file.raw
      }
    },
    handleProgress (evt) {
      this.percentage = evt?.percent
    },
    async imgUploadRequest (param) {
      var vm = this
      this.showProgress = true
      let obj = await platformsettingsUploadPlatformImg(param)
      if (obj.code == 2000) {
        this.showProgress = false;
        let res = ''
        if (obj.data.data[0].indexOf("://") >= 0) {
          res = obj.data.data[0]

        } else {
          res = url.split('/api')[0] + obj.data.data[0]
        }
        setTimeout(() => {
          vm.videourl = res
        }, 100000);
      } else {
        this.showProgress = false;
        vm.$message.warning(obj.msg)
      }
    },
    imgUploadSuccess () {
      this.$refs.uploadDefaultImage.clearFiles()
      this.percentage = 0
    }
  },
}
</script>

<style scoped>
.video-uploader {
  width: 100%;
}

.ly-video-uploader-icon {
  font-size: 28px !important;
  color: #8c939d;
  text-align: center
}
</style>