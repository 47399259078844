<template>
  <div>
    <ly-crud ref="lycrud" v-bind="crudConfig">
      <template v-slot:searchBar-r="">
        <el-form-item label="">
          <el-button @click="syncTZGoods" type="warning" icon="refresh">同步商品类目</el-button>
        </el-form-item>
      </template>
      <template v-slot:customHandle="slotProps">
        <span class="table-operate-btn" @click="handleClick(slotProps.row, 'disable')"
          v-show="hasPermission(this.$route.name, 'Disable')">
          <span v-if="slotProps.row.status">禁用</span>
          <span v-else>启用</span>
        </span>
      </template>
    </ly-crud>
  </div>
</template>

<script lang="jsx">
import { douyinProductcategory, douyinProductcategoryAdd, douyinProductcategoryDelete, douyinProductcategoryEdit, douyinProductcategoryDisablestatus, douyinproductcategorySync } from '@/api/api'
import LyCrud from "@/components/lycrud";
import templateData from "@/components/dict/crudTemplateData"
import { h, resolveComponent } from 'vue';
export default {
  name: "dyGoodsCategoryManage",
  components: { LyCrud },
  data () {
    return {
      defaultImg: require('../../assets/img/avatar.jpg'),
      //crud配置
      crudConfig: {
        //crud请求方法配置
        crudRequest: {
          add: douyinProductcategoryAdd,
          del: douyinProductcategoryDelete,
          edit: douyinProductcategoryEdit,
          search: douyinProductcategory,
        },
        //搜索栏目配置
        searchBar: {
          showSearchBar: true,//显示搜索栏目
          searchColums: [
            { label: '名称', type: 'input', prop: 'name', width: 200, maxlength: 60, placeholder: '请输入名称' },
            { label: '父id', type: 'input', prop: 'parent_id', width: 200, maxlength: 60, placeholder: '请输入父id' },
            {
              label: '等级', type: 'select', prop: 'level', width: 100, placeholder: '请选择',
              options: [
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
              ]
            }, {
              label: '状态', type: 'select', prop: 'status', width: 100, placeholder: '请选择',
              options: [
                { value: 1, label: '正常' },
                { value: 0, label: '禁用' }
              ]
            },
          ]
        },
        //显示分页
        showPagination: true,
        //分页配置
        pageparams: {
          limit: 10,//每页显示的条数(默认每页显示10条)//非必填
          //pageSizes:[10,20,30,40,50,200],//非必填
          // layout:'total, sizes, prev, pager, next, jumper',//非必填
        },
        //crud按钮配置
        rowHandle: {
          width: 180,//操作列宽度,0表示不显示表格操作列
          fixed: "right",//固定操作列在右侧
          permission: {//增删改查按钮权限控制（是否显示）
            add: false,//bool型 this.$options.name获取当前组件的name本例为userManageCrud
            del: false,
            edit: this.hasPermission(this.$options.name, 'Update'),
            search: this.hasPermission(this.$options.name, 'Search'),
            detail: false,
          }
        },
        //crud弹窗属性
        formOptions: {
          width: '45%',//dialog弹窗宽度：类型：百分比或字符串
          gutter: 20, // Layout布局栅格间隔
        },
        //crud表格属性
        tableOptions: {
          border: true,
          showHeader: true,
        },
        showSequence: false,//显示序号
        showSelectable: false,//表格显示复选项框
        //table表头列
        tableColumns: [
          // {label:'用户头像',type:'image',prop:'avatar',minWidth:'60',sortable: false,hidden:false,render:(row)=>{
          //     let elImage = resolveComponent('el-image')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
          //     return h(elImage,{
          //          src:row.avatar ? row.avatar : this.defaultImg,
          //          previewSrcList:[row.avatar ? row.avatar : this.defaultImg],//开启预览，原preview-src-list属性在h渲染方法中，-后字母大写代替即可：previewSrcList
          //          previewTeleported:true,//插入至body元素上
          //          style:"width: 30px;height: 30px",
          //     },)
          //  }
          // },
          // {label:'ID',prop:'id',type:'input',minWidth:'100',sortable: false,hidden:true,
          //     form:{
          //          //表单属性
          //          span:24,
          //          hidden:true,//编辑时隐藏，添加时去除
          //      }
          // },
          {
            label: '分类ID', prop: 'id', type: 'input', minWidth: '100', sortable: false, hidden: false,
            form: {
              //表单属性
              span: 24,
              hidden: true,//编辑时隐藏，添加时去除
            }
          },
          {
            label: '名称', prop: 'name', type: 'input', minWidth: '160', sortable: false, hidden: false,
            form: {
              //表单属性
              span: 24,
              rules: [{ required: true, message: '名称必填项' }],
              placeholder: '请输入名称',
            }
          },
          {
            label: '排序', prop: 'sort', type: 'number', minWidth: '110', sortable: false, hidden: false,
            form: {
              //表单属性
              span: 24,
              rules: [{ required: true, message: '排序必填项' }],
              placeholder: '数字越大越靠前',
              defaultValue: 1,//新增时的默认值
            }
          },
          { label: '添加/修改时间', prop: 'update_datetime', minWidth: '180', sortable: false, hidden: false },
          {
            label: '状态', prop: 'status', type: 'radio', minWidth: '100', sortable: false, hidden: false,
            render: (row) => {
              let elTag = resolveComponent('el-tag')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
              if (!row.status) {
                return h(elTag, {
                  type: 'warning'
                }, () => '禁用');//用()=>函数方式返回文本值，避免出现console警告：默认槽位遇到非函数值
              } else {
                return h(elTag, {
                  type: 'success'
                }, () => '正常');
              }
            },
            form: {
              //表单属性
              span: 24,
              options: templateData.STATUS_BOOL,//radio选项属性值
              defaultValue: true,//新增时的默认值
              // valueChange(e){//值变化回调事件
              //     console.log(e,'---变化的值')
              // },
            }
          },
        ],
      },

    }
  },
  methods: {
    setFull () {
      this.$refs.lycrud.setFull()
    },
    syncTZGoods () {
      let vm = this
      vm.$confirm('同步商品比较耗时，同步期间不要做其他操作！！！，您确定要同步吗？', {
        closeOnClickModal: false,
        type: 'warning',
      }).then(res => {
        vm.loadingPage = true
        douyinproductcategorySync().then(res => {
          vm.$refs.lycrud.handleRefresh()//刷新表格数据
        })
      }).catch(() => {

      })
    },
    //自定义操作列按钮方法
    handleClick (row, flag) {
      let vm = this
      if (flag == 'disable') {
        douyinProductcategoryDisablestatus({ id: row.id }).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.$refs.lycrud.handleRefresh()//刷新表格数据
          } else {
            vm.$message.warning(res.msg)
          }
        })
      }
    },
  },
}
</script>

<style scoped></style>