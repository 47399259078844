<script setup>
import { onMounted, reactive, ref } from 'vue'
import { douyinuserVipRecord } from '@/api/api'
import { ElMessage } from 'element-plus'
import Pagination from "@/components/Pagination"

const isFull = ref(false)
const tableHeight = ref(500)
const loadingPage = ref(false)
const vipList = ref([])
const pageparm = reactive({
  page: 1,
  limit: 10,
  total: 0,
  vip_id: '',
  user: '',
  is_pay: ''
})
const sourceList = reactive([
  { id: 1, name: '已支付' },
  { id: 0, name: '未支付' },
])

// 列表数据
const getdouyinvipInfo = async () => {
  douyinuserVipRecord(pageparm).then(res => {
    if (res.code == 2000) {
      vipList.value = res.data.data
      pageparm.page = res.data.page
      pageparm.limit = res.data.limit
      pageparm.total = res.data.total
    } else {
      ElMessage({ message: res.msg, type: 'warning' })
    }
  })
}
onMounted(() => {
  getdouyinvipInfo()
})
// 点击分页
const callFather = (parm) => {
  pageparm.page = parm.page
  pageparm.limit = parm.limit
  getdouyinvipInfo()
}
const search = () => {
  pageparm.page = 1
  pageparm.limit = 10
  getdouyinvipInfo()
}
const handleEdit = (row, flag) => {
  if (flag == "reset") {
    pageparm.page = 1
    pageparm.limit = 10
    pageparm.vip_id = ''
    pageparm.user = ''
    pageparm.is_pay = ''
    getdouyinvipInfo()
  }
}
</script>
<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="pageparm" label-position="left">
        <el-form-item label="套餐id：">
          <el-input size="default" v-model.trim="pageparm.vip_id" maxlength="60" clearable placeholder="套餐id"
            @change="search" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名、id、手机号：">
          <el-input size="default" v-model.trim="pageparm.user" maxlength="60" clearable placeholder="用户姓名、id、手机号"
            @change="search" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="是否支付：">
          <el-select v-model="pageparm.is_pay" placeholder="请选择" clearable @change="search" style="width:120px">
            <el-option v-for="item in sourceList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=""><el-button @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button></el-form-item>
      </el-form>
    </div>
    <div class="table">
      <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="vipList" ref="tableref" v-loading="loadingPage"
        style="width: 100%">
        <el-table-column width="80" prop="id" label="id"></el-table-column>
        <el-table-column width="120" prop="userinfo.id" label="用户id" show-overflow-tooltip></el-table-column>
        <el-table-column width="160" prop="userinfo.nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
        <el-table-column width="120" prop="userinfo.mobile" label="用户手机号" show-overflow-tooltip></el-table-column>
        <el-table-column width="100" prop="vip_info.id" label="套餐id" show-overflow-tooltip></el-table-column>
        <el-table-column width="100" prop="vip_info.name" label="套餐名称" show-overflow-tooltip></el-table-column>
        <el-table-column width="80" prop="now_price" label="现价">
          <template #default="scope">
            {{ scope.row.now_price / 100 }}
          </template>
        </el-table-column>
        <el-table-column width="90" prop="validity_days" label="有效天数"></el-table-column>
        <el-table-column width="170" prop="update_datetime" label="购买时间"></el-table-column>
        <el-table-column width="100" prop="status" label="是否支付">
          <template #default="scope">
            {{ scope.row.is_pay ? '已付款' : '未付款' }}
          </template>
        </el-table-column>
        <el-table-column width="100" prop="status" label="支付方式">
          <template #default="scope">
            {{ scope.row.pay_method === 10 ? '微信' : '' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
  </div>
</template>
<style></style>