<script setup>
import { onMounted, reactive, ref } from 'vue'
import { douyintaskOrder, douyintaskOrderEdit } from '@/api/api'
import { ElMessage } from 'element-plus'
import Pagination from "@/components/Pagination"
import LyDialog from "@/components/dialog/dialog2.vue"

const isFull = ref(false)
const tableHeight = ref(500)
const loadingPage = ref(false)
const tankList = ref([])
const pageparm = reactive({
  page: 1,// 页码
  limit: 10,// 分页大小
  total: 0,
  beginAt: '', // 搜索开始日期2024-01-01
  endAt: '', // 搜索结束日期2024-04-01
  task_type: '', // 任务类型 1投流 2现金
  task_id: '', // 任务id
  product_id: '', // 产品id
  user: '', // 用户搜索
  status: '', // 订单状态 1进行中 2已完成 -1已失效
  audit_status: '', // 审核状态 10待审核 20 审核通过 30 审核未通过
})
const dateTime = ref('')
// 列表数据
const getdouyintaskOrder = async () => {
  // delete pageparm.total
  douyintaskOrder(pageparm).then(res => {
    if (res.code == 2000) {
      tankList.value = res.data.data
      pageparm.page = res.data.page
      pageparm.limit = res.data.limit
      pageparm.total = res.data.total
    } else {
      ElMessage({ message: res.msg, type: 'warning' })
    }
  })
}
const search = () => {
  if (dateTime.value) {
    pageparm.beginAt = dateTime.value[0]
    pageparm.endAt = dateTime.value[1]
  }
  pageparm.page = 1
  pageparm.limit = 10
  getdouyintaskOrder()
}
onMounted(() => {
  getdouyintaskOrder()
})
// 点击分页
const callFather = (parm) => {
  pageparm.page = parm.page
  pageparm.limit = parm.limit
  getdouyintaskOrder()
}

const loadingSave = ref(false)
const isDialogShow = ref(false)
const dialogTitle = ref('添加类目')
const formData = ref({
  id: '',
  audit_status: 20, //审核状态  20审核通过  30审核未通过
  audit_remarks: '', //审核备注
  reward_amount: '' //审核通过时的奖励金额
})
const handleEdit = (row, flag) => {
  if (flag == 'edit') {
    formData.value.id = row.id
    formData.value.audit_status = ''
    formData.value.audit_remarks = ''
    formData.value.reward_amount = ''
    dialogTitle.value = '订单审核'
    isDialogShow.value = true
  } else if (flag == "reset") {
    pageparm.page = 1
    pageparm.limit = 10
    pageparm.beginAt = ''
    pageparm.endAt = ''
    pageparm.task_type = ''
    pageparm.task_id = ''
    pageparm.product_id = ''
    pageparm.user = ''
    pageparm.status = ''
    pageparm.audit_status = ''
    dateTime.value = ''
    getdouyintaskOrder()
  }
}
const rulesForm = ref(null)
const rules = ref({
  audit_status: [{ required: true, message: '请选择审核状态', trigger: 'blur' },],
  audit_remarks: [{ required: true, message: '请输入审核备注', trigger: 'blur' },],
})
const handleDialogClose = () => {
  isDialogShow.value = false
  formData.value = {
    audit_status: '',
    audit_remarks: '',
    reward_amount: '',
  }
}
const submitData = () => {
  if (!rulesForm.value) return
  rulesForm.value.validate(async valid => {
    if (valid) {
      loadingSave.value = true
      let param = {
        ...formData.value
      }
      douyintaskOrderEdit(param).then(res => {
        loadingSave.value = false
        if (res.code === 2000) {
          isDialogShow.value = false
          ElMessage({ message: res.msg, type: 'success' })
          pageparm.page = 1
          getdouyintaskOrder()
        } else {
          ElMessage({ message: res.msg, type: 'warning' })
        }
      })
    }
  })
}
</script>
<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="pageparm" label-position="left">
        <el-form-item label="搜索日期：">
          <el-date-picker v-model="dateTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" format='YYYY-MM-DD' value-format="YYYY-MM-DD" @change="search" />
        </el-form-item>
        <el-form-item label="任务id：">
          <el-input size="default" v-model.trim="pageparm.task_id" maxlength="60" clearable placeholder="任务id"
            @change="search" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="产品id：">
          <el-input size="default" v-model.trim="pageparm.product_id" maxlength="60" clearable placeholder="产品id"
            @change="search" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="用户搜索：">
          <el-input size="default" v-model.trim="pageparm.user" maxlength="60" clearable placeholder="用户"
            @change="search" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="任务类型：">
          <el-select v-model="pageparm.task_type" placeholder="请选择" clearable @change="search" style="width:120px">
            <el-option value="1" label="投流任务"></el-option>
            <el-option value="2" label="现金任务"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select v-model="pageparm.status" placeholder="请选择" clearable @change="search" style="width:120px">
            <el-option value="1" label="进行中"></el-option>
            <el-option value="2" label="已完成"></el-option>
            <el-option value="3" label="已失效"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态：">
          <el-select v-model="pageparm.audit_status" placeholder="请选择" clearable @change="search" style="width:120px">
            <el-option value="0" label="待审核"></el-option>
            <el-option value="20" label="已审核"></el-option>
            <el-option value="30" label="未通过"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=""><el-button @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button></el-form-item>
      </el-form>
    </div>
    <div class="table">
      <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="tankList" ref="tableref" v-loading="loadingPage"
        style="width: 100%">
        <el-table-column width="220" prop="order_no" label="订单号"></el-table-column>
        <el-table-column width="120" prop="userinfo.mobile" label="用户手机号"></el-table-column>
        <el-table-column width="90" prop="userinfo.nickname" label="用户昵称"></el-table-column>
        <el-table-column width="100" prop="task_info.task_name" label="任务名称"></el-table-column>
        <el-table-column width="110" prop="task_info.task_type_name" label="任务类型名称"></el-table-column>
        <el-table-column width="110" prop="task_info.start_date" label="任务开始日期"></el-table-column>
        <el-table-column width="110" prop="task_info.end_date" label="任务结束日期"></el-table-column>
        <el-table-column width="80" prop="task_info.reward_amount" label="任务奖励金额"></el-table-column>
        <el-table-column width="120" prop="task_info.reward_explain" label="任务奖励说明"></el-table-column>
        <el-table-column width="150" prop="product_info.title" label="商品标题"></el-table-column>
        <el-table-column width="100" prop="product_info.cover" label="商品图片">
          <template #default="scope">
            <el-image :src="scope.row.product_info.cover" :preview-src-list="[scope.row.product_info.cover]"
              :preview-teleported="true" style="width: 30px;height: 30px" :onerror="defaultImg"></el-image>
          </template>
        </el-table-column>
        <el-table-column width="100" label="订单状态">
          <template #default="scope">
            {{ scope.row.status === 1 ? '进行中' : scope.row.status === 2 ? '已完成' : '已失效' }}
          </template>
        </el-table-column>
        <el-table-column width="170" prop="create_datetime" label="创建时间"></el-table-column>
        <el-table-column width="170" prop="update_datetime" label="更新时间"></el-table-column>
        <!-- <el-table-column width="100" label="任务状态">
          <template #default="scope">
            {{ scope.row.status === 1 ? '启用' : '禁用' }}
          </template>
        </el-table-column> -->
        <el-table-column width="100" label="审核状态">
          <template #default="scope">
            {{ scope.row.audit_status === 30 ? '未通过' : scope.row.audit_status === 20 ? '已审核' : '待审核' }}
          </template>
        </el-table-column>
        <!-- 操作列 -->
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
            </div>
          </template>
          <template #default="scope">
            <span v-if="scope.row.audit_status === 0" class="table-operate-btn"
              @click="handleEdit(scope.row, 'edit')">审核</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
  </div>
  <LyDialog v-model="isDialogShow" v-if="isDialogShow" :title="dialogTitle" width="400px" top="30px"
    :before-close="handleDialogClose">
    <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="top" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="审核状态" prop="audit_status">
            <el-radio-group v-model="formData.audit_status">
              <el-radio :label="20">审核通过</el-radio>
              <el-radio :label="30">审核未通过</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="审核备注" prop="audit_remarks">
            <el-input v-model="formData.audit_remarks" type="textarea" placeholder="请输入审核备注"
              style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="formData.audit_status == 20">
          <el-form-item label="奖励金额" prop="reward_amount">
            <el-input type="number" v-model="formData.reward_amount" placeholder="请输入奖励金额"
              style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
      <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
    </template>
  </LyDialog>
</template>
<style></style>